import { classNames } from "~/utils/formatters";
import { forwardRef } from "react";
import { useDisclosure } from "~/utils/hooks";

const Input = forwardRef(
  (
    {
      name,
      label,
      leadingIcon: LeadingIcon,
      type = "text",
      showLabel = false,
      ...inputProps
    }: {
      name: string;
      label: string;
      type?: "email" | "text" | "password" | "number" | "tel" | "date";
      leadingIcon?: React.VFC<{ className?: string }>;
      showLabel?: boolean;
    } & Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      "name" | "label" | "type" | "id" | "className" | "style"
    >,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { isOpen: showPassword, toggle } = useDisclosure();

    return (
      <div>
        <label
          htmlFor={name}
          className={
            showLabel ? "mb-2 inline-block text-lg font-medium" : "sr-only"
          }
        >
          {label}
        </label>
        <div className="group relative">
          {LeadingIcon ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-2.5">
              <div className="transition-color grid place-items-center rounded-full bg-sky-400 p-1.5">
                <LeadingIcon className="h-5 w-5 stroke-white" />
              </div>
              <div className="absolute inset-y-0 left-0 grid place-items-center pl-1">
                <div className="h-11 w-11 bg-gradient-radial-sky opacity-0 mix-blend-color-dodge transition-opacity group-hover:opacity-100"></div>
              </div>
            </div>
          ) : null}
          <input
            type={
              type === "password" ? (showPassword ? "text" : "password") : type
            }
            style={{
              backgroundImage:
                "radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.3))",
            }}
            className={classNames(
              "block w-full rounded-3xl border border-white border-opacity-30 bg-transparent bg-clip-padding py-3 text-base text-white placeholder-white placeholder-opacity-60 transition hover:border-sky-400 focus:border-sky-400 focus:ring-sky-400",
              LeadingIcon !== undefined ? "pl-14" : "pl-4",
              type === "password" ? "pr-14" : "pr-4",
            )}
            id={name}
            name={name}
            ref={ref}
            {...inputProps}
          />
          {type === "password" ? (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
              <button
                type="button"
                onClick={toggle}
                className="inline-flex shrink-0 items-center rounded-2xl border border-transparent bg-white bg-opacity-0 p-1.5 text-sm text-white transition-colors hover:bg-opacity-5"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);
if (process.env.NODE_ENV === "development") {
  Input.displayName = "Input";
}

export { Input };
